import { reactive } from 'vue'

// https://vuejs.org/guide/scaling-up/state-management.html#simple-state-management-with-reactivity-api

// Attention with SSR:
// https://vuejs.org/guide/scaling-up/ssr.html#cross-request-state-pollution

export const store = reactive({
  globals: {},
  isSimpleLanguage: false,
  alternates: [],
})
